import styled from 'styled-components'
import * as Colors from '../constants/colors'
import { PHONE } from '../constants/measurements'

export const Container = styled.div``

export const TrainerImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 3px solid white;
  @media (max-width: ${PHONE}) {
    width: 80px;
    height: 80px;
  }
`
export const TrainerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${Colors.themeColors.textColor};
  margin-bottom: 30px;
`

export const SubTitle = styled.div`
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 15px;
  color: ${Colors.themeColors.textColor};
`

export const AboutWrap = styled.div`
  width: 45%;
  margin-top: 30px;
`

export const SubText = styled.div`
  font-size: 16px;
  opacity: 0.75;
  margin-bottom: 15px;
  color: ${Colors.themeColors.textColor};
`

export const WorkoutTitle = styled.div`
  color: white;
  font-size: 26px;
  font-size: 500;
  color: ${Colors.themeColors.textColor};

  @media (max-width: ${PHONE}) {
    font-size: 22px;
  }
`

export const TrainerName = styled.div`
  color: white;
  margin-top: 8px;
  font-size: 18px;
  color: ${Colors.themeColors.textColor};

  @media (max-width: ${PHONE}) {
    font-size: 16px;
  }
`
export const CloseIcon = styled.div`
  width: 100%;
`

export const Wrap = styled.div`
  margin-top: 20px;
  padding: 60px;
  @media (max-width: ${PHONE}) {
    margin-top: 0;
    padding: 15px;
  }
`

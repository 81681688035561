import React, { useState } from 'react'
import * as S from '../styles/trainer-profile.styles'
import Layout from '../templates/Layout'
import LiveLayout from '../templates/LiveLayout'
import * as session from '../util/session'
import ScheduleComponent from '../components/live/schedule'
import BackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import * as Colors from '../constants/colors'
import { navigate } from 'gatsby'
import { BASIC_LIVE_SCHEDULE, TRAINER_SCHEDULE } from '../constants/routes'
import { withErrorHandler } from '../components/errorHandler'

const TrainerProfile = (props: any) => {
  const [loading] = useState(false)

  const isTrainer = session.isTrainer()

  const trainer = props.location.state && props.location.state.trainer

  if (!trainer) {
    return 'No trainer data'
  }

  const onGoBack = () => {
    navigate(isTrainer ? TRAINER_SCHEDULE : BASIC_LIVE_SCHEDULE)
  }

  const getRender = () => {
    if (loading) {
      return 'Loading...'
    }

    return (
      <S.Wrap>
        <S.CloseIcon>
          <IconButton onClick={onGoBack}>
            <BackIcon style={{ color: Colors.themeColors.textColor }} />
          </IconButton>
        </S.CloseIcon>
        {trainer && (
          <S.Container>
            <S.TrainerWrap>
              <S.TrainerImage
                src={trainer && trainer.images.small}
              ></S.TrainerImage>
              <S.WorkoutTitle>
                {trainer.firstName} {trainer.lastName}
              </S.WorkoutTitle>
              <S.TrainerName>
                {trainer.city} {trainer.state}
              </S.TrainerName>
              <S.SubText>{trainer.bio}</S.SubText>
            </S.TrainerWrap>
            <ScheduleComponent
              location={props.location}
              trainerId={trainer?.id as number}
            />
          </S.Container>
        )}
      </S.Wrap>
    )
  }

  return isTrainer ? (
    <LiveLayout title="Trainer profile" location={props.location}>
      {getRender()}
    </LiveLayout>
  ) : (
    <Layout>{getRender()}</Layout>
  )
}

export default withErrorHandler(TrainerProfile)
